<template>
    <div>
        <h1>Selected Projects</h1>
        <b-container class="proj-container">
            <b-row align-v="center" align-h="center">
                <project-card
                    v-for="job in jobs"
                    :key="job.id"
                    :obj="job"
                ></project-card>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import ProjectCard from "@/components/ProjectCard.vue";
import projects from '../assets/projects/cards.json';

export default {
    name: "Home",
    components: {
        "project-card": ProjectCard
    },
    mounted() {
        this.fetchData();
    },

    data() {
        return {
            jobs: [],
            displayJobs: [],
            currentPage: 1,
            rows: 1,
            perPage: 3
        };
    },
    methods: {
        async fetchData() {
            this.jobs = projects;
            this.displayJobs = projects.slice(0, 3);

            this.rows = this.jobs.length;
            // console.log(val);
        },
        paginate(currentPage) {
            const start = (currentPage - 1) * this.perPage;
            this.displayJobs = this.jobs.slice(start, start + 3);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/projects/style.scss";

body {
    // overflow: visible !important;
    // user-select: auto !important;
    -webkit-touch-callout: default;
    -webkit-text-size-adjust: auto;
    -webkit-user-select: auto; 
}

// .proj-container {
//     background-color: red;
// }
</style>