<template>
    <div>
      <b-col style="padding: 0px 30px">
        <b-card :img-src="mainImage()" img-alt="project info" img-top tag="article" bg-variant="dark" class="mb-3 my-card">
          <b-card-title class="text-center my-card-title">
            {{ obj.name }}
          </b-card-title>        
          <a class="subtext card-block stretched-link text-decoration-none" :href="endpoint()">
            {{ obj.subtext }}
          </a> 
        </b-card>
      </b-col>
    </div>
</template>

<script>
    export default {
        props: ['obj'],
        methods: {
          endpoint() {
            return "/projects/" + this.obj.id;
          },
          mainImage() {
            try {
              return require( "@/assets/projects/" + this.obj.image );
            }
            catch(error) {
              console.log("failed to load image with path: " + this.obj.image);
              console.table(error);
            }
          }
        },
        mounted() {
          // console.log( "mainImage(): " + this.mainImage() );
        }
    }
</script>

<style lang="scss" scoped>
@import "../assets/common.scss";

.subtext {
    transition: color 0.3s ease;
    color: rgb(55, 226, 209);

}
.subtext:hover {
  color:$hover-color;
}

.my-card {
    max-width:30rem;
    border-color: clear;
    background-color:#1a1d21a0 !important;
    transition: border-color 0.55s ease;
}

.my-card:hover {
  border-color: $hover-color;
}

// make max-width of card wider when there is only one column
@media only screen and (max-width: 1200px) {
  .my-card {
    max-width:50rem;
  }
}

// FIXME: title color isn't animating
// - might be because of the stretched-link controlling hover
.my-card-title {
    transition: color 0.3s ease;
    color: white;

}
.my-card-title:hover {
  color: red;
}

</style>